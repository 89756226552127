.overflow-name-table {
    min-width: 150px;
    max-width: calc(20vw);
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-group {
    position: relative;
    margin-bottom: 10px;
}

.form-group label {
    font-size: 12px;
    color: #6c757d;
    margin-bottom: 4px;
}

.form-control {
    border: initial;
    outline: initial !important;
    background: #f8f9fa;
    border: 1px solid #ced4da;
    color: #212529;
}

.form-control::placeholder {
    color: #adb5bd;
}

.form-control.rounded,
.form-control.form-control-rounded,
.rdt.form-control-rounded .form-control {
    border-radius: 4px;
}

select.form-control {
    -webkit-appearance: none;
}

.input-group [type="text"].form-control {
    height: 34px;
}

.input-group-append .btn {
    height: 34px;
    border: 0;
}

/* checkbox-custom */
.checkbox,
.radio {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
}

.checkbox:hover input~.checkmark,
.radio:hover input~.checkmark {
    background-color: #adb5bd;
}

.checkbox input,
.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox input:checked~.checkmark,
.radio input:checked~.checkmark {
    background-color: #007bff;
}

.checkbox input:checked~.checkmark:after,
.radio input:checked~.checkmark:after {
    display: block;
}

.checkbox input[disabled]~*,
.radio input[disabled]~* {
    color: #dee2e6;
}

.checkbox .checkmark,
.radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    background-color: #dee2e6;
}

.checkbox .checkmark:after,
.radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    right: 0;
    width: 4px;
    bottom: 0;
    margin: auto;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkbox-primary input:checked~.checkmark {
    background-color: #007bff !important;
}

.checkbox-secondary input:checked~.checkmark {
    background-color: #6c757d !important;
}

.checkbox-success input:checked~.checkmark {
    background-color: #28a745 !important;
}

.checkbox-info input:checked~.checkmark {
    background-color: #17a2b8 !important;
}

.checkbox-warning input:checked~.checkmark {
    background-color: #ffc107 !important;
}

.checkbox-danger input:checked~.checkmark {
    background-color: #dc3545 !important;
}

.checkbox-light input:checked~.checkmark {
    background-color: #f8f9fa !important;
}

.checkbox-dark input:checked~.checkmark {
    background-color: #343a40 !important;
}

.checkbox-outline-primary:hover input~.checkmark {
    background-color: #e9ecef;
}

.checkbox-outline-primary input:checked~.checkmark {
    background-color: #fff !important;
}

.checkbox-outline-primary .checkmark {
    background: #fff;
    border: 1px solid #007bff;
}

.checkbox-outline-primary .checkmark:after {
    border-color: #007bff;
}

.checkbox-outline-secondary:hover input~.checkmark {
    background-color: #e9ecef;
}

.checkbox-outline-secondary input:checked~.checkmark {
    background-color: #fff !important;
}

.checkbox-outline-secondary .checkmark {
    background: #fff;
    border: 1px solid #6c757d;
}

.checkbox-outline-secondary .checkmark:after {
    border-color: #6c757d;
}

.checkbox-outline-success:hover input~.checkmark {
    background-color: #e9ecef;
}

.checkbox-outline-success input:checked~.checkmark {
    background-color: #fff !important;
}

.checkbox-outline-success .checkmark {
    background: #fff;
    border: 1px solid #28a745;
}

.checkbox-outline-success .checkmark:after {
    border-color: #28a745;
}

.checkbox-outline-info:hover input~.checkmark {
    background-color: #e9ecef;
}

.checkbox-outline-info input:checked~.checkmark {
    background-color: #fff !important;
}

.checkbox-outline-info .checkmark {
    background: #fff;
    border: 1px solid #17a2b8;
}

.checkbox-outline-info .checkmark:after {
    border-color: #17a2b8;
}

.checkbox-outline-warning:hover input~.checkmark {
    background-color: #e9ecef;
}

.checkbox-outline-warning input:checked~.checkmark {
    background-color: #fff !important;
}

.checkbox-outline-warning .checkmark {
    background: #fff;
    border: 1px solid #ffc107;
}

.checkbox-outline-warning .checkmark:after {
    border-color: #ffc107;
}

.checkbox-outline-danger:hover input~.checkmark {
    background-color: #e9ecef;
}

.checkbox-outline-danger input:checked~.checkmark {
    background-color: #fff !important;
}

.checkbox-outline-danger .checkmark {
    background: #fff;
    border: 1px solid #dc3545;
}

.checkbox-outline-danger .checkmark:after {
    border-color: #dc3545;
}

.checkbox-outline-light:hover input~.checkmark {
    background-color: #e9ecef;
}

.checkbox-outline-light input:checked~.checkmark {
    background-color: #fff !important;
}

.checkbox-outline-light .checkmark {
    background: #fff;
    border: 1px solid #f8f9fa;
}

.checkbox-outline-light .checkmark:after {
    border-color: #f8f9fa;
}

.checkbox-outline-dark:hover input~.checkmark {
    background-color: #e9ecef;
}

.checkbox-outline-dark input:checked~.checkmark {
    background-color: #fff !important;
}

.checkbox-outline-dark .checkmark {
    background: #fff;
    border: 1px solid #343a40;
}

.checkbox-outline-dark .checkmark:after {
    border-color: #343a40;
}

.radio .checkmark {
    border-radius: 50%;
}

.radio .checkmark:after {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: white;
}

.radio-primary input:checked~.checkmark {
    background-color: #007bff;
}

.radio-secondary input:checked~.checkmark {
    background-color: #6c757d;
}

.radio-success input:checked~.checkmark {
    background-color: #28a745;
}

.radio-info input:checked~.checkmark {
    background-color: #17a2b8;
}

.radio-warning input:checked~.checkmark {
    background-color: #ffc107;
}

.radio-danger input:checked~.checkmark {
    background-color: #dc3545;
}

.radio-light input:checked~.checkmark {
    background-color: #f8f9fa;
}

.radio-dark input:checked~.checkmark {
    background-color: #343a40;
}

.radio-outline-primary:hover input~.checkmark {
    background-color: #e9ecef;
}

.radio-outline-primary input:checked~.checkmark {
    background-color: #fff !important;
}

.radio-outline-primary .checkmark {
    background: #fff;
    border: 1px solid #007bff;
}

.radio-outline-primary .checkmark:after {
    border: 0;
    background: #007bff;
}

.radio-outline-secondary:hover input~.checkmark {
    background-color: #e9ecef;
}

.radio-outline-secondary input:checked~.checkmark {
    background-color: #fff !important;
}

.radio-outline-secondary .checkmark {
    background: #fff;
    border: 1px solid #6c757d;
}

.radio-outline-secondary .checkmark:after {
    border: 0;
    background: #6c757d;
}

.radio-outline-success:hover input~.checkmark {
    background-color: #e9ecef;
}

.radio-outline-success input:checked~.checkmark {
    background-color: #fff !important;
}

.radio-outline-success .checkmark {
    background: #fff;
    border: 1px solid #28a745;
}

.radio-outline-success .checkmark:after {
    border: 0;
    background: #28a745;
}

.radio-outline-info:hover input~.checkmark {
    background-color: #e9ecef;
}

.radio-outline-info input:checked~.checkmark {
    background-color: #fff !important;
}

.radio-outline-info .checkmark {
    background: #fff;
    border: 1px solid #17a2b8;
}

.radio-outline-info .checkmark:after {
    border: 0;
    background: #17a2b8;
}

.radio-outline-warning:hover input~.checkmark {
    background-color: #e9ecef;
}

.radio-outline-warning input:checked~.checkmark {
    background-color: #fff !important;
}

.radio-outline-warning .checkmark {
    background: #fff;
    border: 1px solid #ffc107;
}

.radio-outline-warning .checkmark:after {
    border: 0;
    background: #ffc107;
}

.radio-outline-danger:hover input~.checkmark {
    background-color: #e9ecef;
}

.radio-outline-danger input:checked~.checkmark {
    background-color: #fff !important;
}

.radio-outline-danger .checkmark {
    background: #fff;
    border: 1px solid #dc3545;
}

.MuiTooltip-tooltip {
    font-size: 12px !important;
    font-family: "Manrope" !important;
    text-align: center;
}

.radio-outline-danger .checkmark:after {
    border: 0;
    background: #dc3545;
}

.radio-outline-light:hover input~.checkmark {
    background-color: #e9ecef;
}

.radio-outline-light input:checked~.checkmark {
    background-color: #fff !important;
}

.radio-outline-light .checkmark {
    background: #fff;
    border: 1px solid #f8f9fa;
}

.radio-outline-light .checkmark:after {
    border: 0;
    background: #f8f9fa;
}

.radio-outline-dark:hover input~.checkmark {
    background-color: #e9ecef;
}

.radio-outline-dark input:checked~.checkmark {
    background-color: #fff !important;
}

.radio-outline-dark .checkmark {
    background: #fff;
    border: 1px solid #343a40;
}

.radio-outline-dark .checkmark:after {
    border: 0;
    background: #343a40;
}

.switch {
    position: relative;
    display: inline-block;
    padding-left: 50px;
    height: 16px;
}

.switch span:not(.slider) {
    position: relative;
    top: -2px;
    cursor: pointer;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch .slider {
    position: absolute;
    cursor: pointer;
    width: 42px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: #dee2e6;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: -1px;
    bottom: -4px;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.switch input:checked+.slider {
    background-color: #007bff;
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #007bff;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.switch-primary input:checked+.slider {
    background-color: #007bff;
}

.switch-primary input:focus+.slider {
    box-shadow: 0 0 1px #007bff;
}

.switch-secondary input:checked+.slider {
    background-color: #6c757d;
}

.switch-secondary input:focus+.slider {
    box-shadow: 0 0 1px #6c757d;
}

.switch-success input:checked+.slider {
    background-color: #28a745;
}

.switch-success input:focus+.slider {
    box-shadow: 0 0 1px #28a745;
}

.switch-info input:checked+.slider {
    background-color: #17a2b8;
}

.switch-info input:focus+.slider {
    box-shadow: 0 0 1px #17a2b8;
}

.switch-warning input:checked+.slider {
    background-color: #ffc107;
}

.switch-warning input:focus+.slider {
    box-shadow: 0 0 1px #ffc107;
}

.switch-danger input:checked+.slider {
    background-color: #dc3545;
}

.switch-danger input:focus+.slider {
    box-shadow: 0 0 1px #dc3545;
}

.switch-light input:checked+.slider {
    background-color: #f8f9fa;
}

.switch-light input:focus+.slider {
    box-shadow: 0 0 1px #f8f9fa;
}

.switch-dark input:checked+.slider {
    background-color: #343a40;
}

.switch-dark input:focus+.slider {
    box-shadow: 0 0 1px #343a40;
}

[dir="rtl"] .checkbox,
[dir="rtl"] .radio {
    padding-left: 0px;
    padding-right: 28px;
}

[dir="rtl"] .checkbox .checkmark,
[dir="rtl"] .radio .checkmark {
    left: auto;
    right: 0;
}

.invalid-feedback,
.valid-feedback,
.valid-tooltip,
.invalid-tooltip {
    display: none;
}

.invalid-field input {
    border-color: #f44336;
    padding-right: 1.9695rem;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f44336' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f44336' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-field .invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f44336;
}

.invalid-field .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: block;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.711375rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(244, 67, 54, 0.9);
    border-radius: 0.25rem;
}

.valid-field input {
    border-color: #4caf50;
    padding-right: 1.9695rem;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234caf50' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.valid-field .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: block;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.711375rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(76, 175, 80, 0.9);
    border-radius: 0.25rem;
}

.valid-field .valid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #4caf50;
}

.form-wizard .step-active {
    position: relative;
    border-bottom: 2px solid #007bff;
    color: #007bff !important;
}

.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
}

.ReactTags__tagInput {
    flex: 1 1 0;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 14px;
    width: 100%;
    flex: 1 1 0;
    outline: none;
    border: none;
}

/* Styles for selected tags */
.ReactTags__selected {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 3px;
}

.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #007bff;
    font-size: 14px;
    color: white;
    display: inline-block;
    padding: 5px 10px;
    margin: 2px 5px;
    cursor: move;
    border-radius: 20px;
    overflow: hidden;
}

.ReactTags__selected a.ReactTags__remove {
    color: white;
    font-size: 14px;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
    position: absolute;
}

.ReactTags__suggestions ul {
    position: absolute;
    left: 0;
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    width: 200px;
    padding-left: 0;
    margin-left: 0;
    background: white;
}

.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

.ReactTags__suggestions li mark {
    text-decoration: none;
    font-weight: 600;
    background: transparent;
    font-synthesis: 20px;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #f8f9fa;
    cursor: pointer;
}